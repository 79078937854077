import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyPolicy from "../components/privacy-policy"

const PrivacyPolicyPage = ({ data }) => {
  const pageData = data.allContentfulPrivacyPolicy.nodes[0]

  return (
    <Layout>
      <SEO title="Privacy Policy" description={pageData.metaDescription} />
      <PrivacyPolicy
        data={
          pageData.childContentfulPrivacyPolicyContentRichTextNode
            .childContentfulRichText.html
        }
      />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPrivacyPolicy {
      nodes {
        id
        title
        metaDescription
        childContentfulPrivacyPolicyContentRichTextNode {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
`

export default PrivacyPolicyPage
