import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const PrivacyPolicy = ({ data }) => {
  return (
    <section id="privacy-policy">
      <Container>
        <Row>
          <Col>
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PrivacyPolicy
